import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import httpBuildQuery from 'http-build-query'

const urlAPI = process.env.VUE_APP_APIENDPOINT

export const TariffService = {

  postImportFile (content, typeFile) {
    try {
      let queryString = httpBuildQuery(typeFile)
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/tariff/import/excel?${queryString}`, content).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('formulário invalido'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  getSendImported () {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/tariff/send/imported`).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('formulário invalido'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * http://localhost/api/v2/proposal
   *
   * @param filter
   * @returns {Promise<any>}
   */
  getTariffs (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/proposal/options?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * http://localhost/api/v2/proposal/lcl
   *
   * @param filter
   * @returns {Promise<any>}
   */
  getLclTariffs (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/proposal/lcl/options?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * @returns {Promise<any>}
   */
  getAgentOptions (withDefault = true) {
    try {
      let queryString = httpBuildQuery({
        withDefault: withDefault
      })
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/tariffimportrule/fileprovideragent/list?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * @returns {Promise<any>}
   */
  getRuleTypeOptions () {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/tariffimportrule/ruletype/list`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * @returns {Promise<any>}
   */
  getRuleKeysOptions () {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/tariffimportrule/rulekeys/list`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * @returns {Promise<any>}
   */
  getRuleData () {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/tariffimportrule/all`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  saveRules (content) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/tariffimportrule`, content).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('formulário invalido'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getImportedFile (filename) {
    let queryString = httpBuildQuery({
      fileName: filename
    })
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/tariff/file?${queryString}`, { responseType: 'blob' }).then(function (res) {
          resolve({ data: res.data })
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}

export default TariffService
