<template>
  <!-- BEGIN: Quick sidebar-->
  <div>
    <div class="sidenav-backdrop backdrop" @click="hideQuickSidebar"></div>
    <div class="quick-sidebar" id="quick-sidebar">
      <div class="text-right mb-4">
        <div class="buttonClose d-inline-block" @click="hideQuickSidebar">
          <span><i class="rc-Ativo-36"> </i></span>
        </div>
      </div>
      <div class="position-relative custom-scroll h-100" >
        <slot name="Content">
        </slot>
      </div>
    </div>
  </div>
  <!-- END: Quick sidebar-->
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

export default {
  name: 'QuickSidebar',
  methods: {
    hideQuickSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    }
  },
  mounted () {
    this.$nextTick(function () {
      const ps = []
      let customScrolls = document.querySelectorAll('.custom-scroll')
      for (var i = 0; i < customScrolls.length; i++) {
        // eslint-disable-next-line
        ps[ps.length] = new PerfectScrollbar(customScrolls[i])
      }
    })
  }
}
</script>

<style scoped>
  .buttonClose {
    border-left: none;
    border-top: none;
    width: 20px;
    height: 20px;

  }
  .buttonClose span {
    margin-left: 4px;
    color: #84754E;
    font-size: 17px;
    cursor: pointer;

  }
</style>
