<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">

      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <form id="processes-list-filter" ref='processesListFilter' v-on:submit.prevent="applyFilter()">
              <h5 class="mb-0">Filtrar por:</h5>
              <div>
                <div class="md-form mb-0">
                  <input id="internal_code" name="internal_code" ref="internal_code"
                         :title="this.$i18n.t('form.process.internal_code')" class="md-form-control" type="text"
                         :value="filters.filProcesso">
                  <label :class="{'active': filters.filProcesso}">{{ $t('form.process.internal_code') }}</label>
                </div>
              </div>

              <div>
                <div class="md-form mb-0">
                  <input id="customer_reference" name="customer_reference" ref="customer_reference"
                         :title="this.$i18n.t('form.process.customer_reference')" class="md-form-control" type="text"
                         :value="filters.filReferenciaCliente">
                  <label :class="{'active': filters.filReferenciaCliente}">{{ $t('form.process.customer_reference') }}</label>
                </div>
              </div>

              <div>
                <div class="md-form mb-0">
                  <input id="hbl" name="hbl" ref="hbl" :title="this.$i18n.t('form.process.bl_house')"
                         class="md-form-control" type="text" :value="filters.filHBL">
                  <label :class="{'active': filters.filHBL}">{{ $t('form.process.bl_house') }}</label>
                </div>
              </div>

              <h6 class="mb-0 mt-4">{{ $t('form.process.period_time_arrival') }}</h6>
              <div class="row">
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-0">
                      <div class="md-form mb-0">
                <span class="input-icon input-icon-right">
                  <i class="ti-calendar"></i>
                </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.period_time_arrival')"
                               id="arrival_begin_date" ref="arrival_begin_date" readonly
                               class="form-date md-form-control" type="text"
                               :value="$util.formatDate(filters.filPeriodoEmbarqueDtInicio, undefined,null)">
                        <label :class="{'active': filters.filPeriodoEmbarqueDtInicio}">{{ $t('filter.Start') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-4">
                      <div class="md-form mb-4">
                <span class="input-icon input-icon-right">
                  <i class="ti-calendar"></i>
                </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.period_time_arrival')"
                               id="arrival_end_date" ref="arrival_end_date" readonly class="form-date md-form-control"
                               type="text" :value="$util.formatDate(filters.filPeriodoEmbarqueDtFim, undefined, null)">
                        <label :class="{'active': filters.filPeriodoEmbarqueDtFim}">{{ $t('filter.End') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h6 class="mb-0 mt-0">{{ $t('form.process.period_time_departure') }}</h6>
              <div class="row">
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-4">
                      <div class="md-form mb-4">
                <span class="input-icon input-icon-right">
                  <i class="ti-calendar"></i>
                </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.period_time_departure')"
                               id="departure_begin_date" ref="departure_begin_date" readonly
                               class="form-date md-form-control" type="text"
                               :value="$util.formatDate(filters.filPeriodoDesembarqueDtInicio, undefined, null)">
                        <label :class="{'active': filters.filPeriodoDesembarqueDtInicio}">{{ $t('filter.Start') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-4">
                      <div class="md-form mb-4">
                <span class="input-icon input-icon-right">
                  <i class="ti-calendar"></i>
                </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.period_time_departure')"
                               id="departure_end_date" ref="departure_end_date" readonly
                               class="form-date md-form-control" type="text"
                               :value="$util.formatDate(filters.filPeriodoDesembarqueDtFim, undefined, null)">
                        <label :class="{'active': filters.filPeriodoDesembarqueDtFim}">{{ $t('filter.End') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Operation / Modal -->
              <h6 class="mb-3 mt-4">{{ $t('operation type and modal') }}</h6>
              <div class="row">
                <!-- Filter Operation  -->
                <div class="col-md-6">
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Importação</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning">
                        <input id="operation_impo" value="2" type="checkbox" ref="operation_impo" :checked="filters.filTipoOperacaoModalImportacao"><span></span></label>
                    </div>
                  </div>
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Exportação</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning">
                        <input id="operation_expo" value="1" type="checkbox" ref="operation_expo" :checked="filters.filTipoOperacaoModalExportacao"><span></span>
                      </label>
                    </div>
                  </div>
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Nacional</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning">
                        <input id="operation_nac" value="3" type="checkbox"  ref="operation_nac" :checked="filters.filTipoOperacaoModalNacional"><span></span>
                      </label>
                    </div>
                  </div>
                </div>
                <!-- Filter Modal  -->
                <div class="col-md-6">
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Marítimo</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning">
                        <input id="modal_mar" value="2" type="checkbox" ref="modal_mar" :checked="filters.filTipoOperacaoModalMaritimo"><span></span></label></div>
                  </div>
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Aéreo</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning">
                        <input id="modal_air" value="1" type="checkbox" ref="modal_air" :checked="filters.filTipoOperacaoModalAereo"><span></span>
                      </label>
                    </div>
                  </div>
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Terrestre</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning">
                        <input id="modal_land" value="3" type="checkbox"  ref="modal_land" :checked="filters.filTipoOperacaoModalTerrestre"><span></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}
                      </button>
                    </div>
                    <div class="col-6">
                      <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{
                        $t('filter.reset') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <div class="col-12 col-sm-12">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <!--  Actions -->
                <div class="flexbox px-0 py-3 mb-3">
                  <div class="d-flex align-items-center">
                  </div>
                  <div class="btn-group" role="group" aria-label="false">
                    <router-link :to="{name: 'TariffImportNew'}" class="btn btn-sm btn-outline-light pull-right">
                      Novo
                    </router-link>
                    <router-link v-show="isSuperAdmin" :to="{name: 'TariffImportConfig'}"
                      class="btn btn-sm btn-outline-light pull-right">
                      Configurações
                    </router-link>
                  </div>
                </div>
                <!--  End Actions -->

                <!-- Table -->
                <div class="table-responsive px-0">
                  <vuetable ref="vuetable"
                    :noDataTemplate="this.$i18n.t('noting to result')"
                    :api-url="endpoint"
                    :fields="vTableFields"
                    :sort-order="sortOrder"
                    :css="css.table"
                    pagination-path="pagination"
                    :per-page="20"
                    :multi-sort="multiSort"
                    :append-params="filter"
                    multi-sort-key="shift"
                    :http-options="httpOptions"
                    track-by="id_process"
                    @vuetable:pagination-data="onPaginationData"
                    @vuetable:loading="onLoading"
                    @vuetable:loaded="onLoaded">
                    <template slot="actions" slot-scope="option">
                      <div class="custom-actions">
                        <button class="btn btn-link no-padding"
                          v-on:click="fileDownload(option)">
                          <i style="color: #000 !important;" class="ft-download"></i> </button>
                      </div>
                    </template>
                  </vuetable>
                </div>
                <vuetable-pagination
                  ref="pagination"
                  :css="css.pagination"
                  @vuetable-pagination:change-page="onChangePage">
                </vuetable-pagination>
                <!-- END Table -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable, { VuetablePagination } from 'vuetable-2'
import FilterSideBar from '@/components/FilterSideBar.vue'

import dayjs from 'dayjs'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import TariffService from '@/services/TariffService'

export default {
  name: 'TariffImportList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.tariffImport') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      selectedProcess: null,
      processDetail: false,
      endpoint: process.env.VUE_APP_APIENDPOINT + 'v2/tariff/filaprocesso',
      multiSort: true,
      activeFilterList: {
        begin_date: null,
        end_date: null
      },
      filter: {},
      fieldsTable: [
        {
          name: 'nomeArquivoOriginal',
          title: this.$i18n.t('form.filaprocesso.nomeArquivoOriginal'),
          sortField: 'nomeArquivoOriginal',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'tipoArquivo',
          title: this.$i18n.t('form.filaprocesso.tipoArquivo'),
          sortField: 'tipoArquivo',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'dataSolicitacao',
          title: this.$i18n.t('form.filaprocesso.dataSolicitacao'),
          sortField: 'dataSolicitacao',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatDateTime(value)
        },
        {
          name: 'solicitadoPor',
          title: this.$i18n.t('form.filaprocesso.solicitadoPor'),
          sortField: 'solicitadoPor',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatUser(value)
        },
        {
          name: 'dataInicio',
          title: this.$i18n.t('form.filaprocesso.dataInicio'),
          sortField: 'dataInicio',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatDateTime(value)
        },
        {
          name: 'dataTermino',
          title: this.$i18n.t('form.filaprocesso.dataTermino'),
          sortField: 'dataTermino',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatDateTime(value)
        },
        {
          name: 'situacao',
          title: this.$i18n.t('form.filaprocesso.situacao.label'),
          sortField: 'situacao',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.formatStatus(value)
        },
        {
          name: 'actions',
          title: 'Ações',
          width: '150px',
          dataClass: 'text-center',
          titleClass: 'text-center'
        }
      ],
      sortOrder: [
        { field: 'dataSolicitacao', direction: 'desc' },
        { field: 'nomeArquivoOriginal', direction: 'asc' }
      ],
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          rowCss: 'teste',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      }
    }
  },
  components: {
    FilterSideBar,
    Vuetable,
    VuetablePagination,
    Loading
  },
  // Apply filters to local filter
  beforeMount () {
    this.filter.internal_code = this.$route.params.process
    this.filters.filProcesso = this.$route.params.process

    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)

    this.$jQuery('#arrival_begin_date, #arrival_end_date, #departure_begin_date, #departure_end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    buildFilters () {
      if (this.filters.filProcesso) {
        this.filter.internal_code = this.filters.filProcesso
      } else {
        this.filter.internal_code = null
      }

      // Operation Filter
      this.filter.operation = []
      if (this.filters.filTipoOperacaoModalExportacao) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalExportacao)
      }
      if (this.filters.filTipoOperacaoModalImportacao) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalImportacao)
      }
      if (this.filters.filTipoOperacaoModalNacional) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalNacional)
      }

      // Modal Filter
      this.filter.modal = []
      if (this.filters.filTipoOperacaoModalTerrestre) {
        this.filter.modal.push(this.filters.filTipoOperacaoModalTerrestre)
      }
      if (this.filters.filTipoOperacaoModalMaritimo) {
        this.filter.modal.push(this.filters.filTipoOperacaoModalMaritimo)
      }
      if (this.filters.filTipoOperacaoModalAereo) {
        this.filter.modal.push(this.filters.filTipoOperacaoModalAereo)
      }

      this.filter.customer_reference = this.filters.filReferenciaCliente
      this.filter.hbl = this.filters.filHBL
      this.filter.id_status = null
      this.filter.arrival_begin_date = this.filters.filPeriodoEmbarqueDtInicio
      this.filter.arrival_end_date = this.filters.filPeriodoEmbarqueDtFim
      this.filter.departure_begin_date = this.filters.filPeriodoDesembarqueDtInicio
      this.filter.departure_end_date = this.filters.filPeriodoDesembarqueDtFim
    },
    applyStatusFilter (idStatus) {
      event.preventDefault()
      this.filter.id_status = idStatus
      this.$refs.vuetable.refresh()
    },
    // Loader
    applyFilter () {
      let dayParse = 'DD/MM/YYYY'

      // Operation Filter
      this.filters.filTipoOperacaoModalExportacao = this.$refs.operation_expo.checked ? parseInt(this.$refs.operation_expo.value) : null
      this.filters.filTipoOperacaoModalImportacao = this.$refs.operation_impo.checked ? parseInt(this.$refs.operation_impo.value) : null
      this.filters.filTipoOperacaoModalNacional = this.$refs.operation_nac.checked ? parseInt(this.$refs.operation_nac.value) : null

      // Modal Filter
      this.filters.filTipoOperacaoModalTerrestre = this.$refs.modal_land.checked ? parseInt(this.$refs.modal_land.value) : null
      this.filters.filTipoOperacaoModalMaritimo = this.$refs.modal_mar.checked ? parseInt(this.$refs.modal_mar.value) : null
      this.filters.filTipoOperacaoModalAereo = this.$refs.modal_air.checked ? parseInt(this.$refs.modal_air.value) : null

      this.filters.filProcesso = this.$refs.internal_code.value
      this.filters.filReferenciaCliente = this.$refs.customer_reference.value
      this.filters.filHBL = this.$refs.hbl.value
      // this.filters.id_status = null
      this.filters.filPeriodoEmbarqueDtInicio = dayjs(this.$refs.arrival_begin_date.value, dayParse).isValid() ? dayjs(this.$refs.arrival_begin_date.value, dayParse).toISOString() : null
      this.filters.filPeriodoEmbarqueDtFim = dayjs(this.$refs.arrival_end_date.value, dayParse).isValid() ? dayjs(this.$refs.arrival_end_date.value, dayParse).toISOString() : null
      this.filters.filPeriodoDesembarqueDtInicio = dayjs(this.$refs.departure_begin_date.value, dayParse).isValid() ? dayjs(this.$refs.departure_begin_date.value, dayParse).toISOString() : null
      this.filters.filPeriodoDesembarqueDtFim = dayjs(this.$refs.departure_end_date.value, dayParse).isValid() ? dayjs(this.$refs.departure_end_date.value, dayParse).toISOString() : null

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()
      this.$refs.vuetable.refresh()
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuichSidebar()
      // TODO: Ezequiel
      this.filters.filProcesso = null
      this.filters.filReferenciaCliente = null
      this.filters.filHBL = null
      // this.filter.id_status = null
      this.filters.filPeriodoEmbarqueDtInicio = null
      this.filters.filPeriodoEmbarqueDtFim = null
      this.filters.filPeriodoDesembarqueDtInicio = null
      this.filters.filPeriodoDesembarqueDtFim = null

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      // this.filter.internal_code = null
      // this.filter.customer_reference = null
      // this.filter.hbl = null
      // // this.filter.id_status = null
      // this.filter.arrival_begin_date = null
      // this.filter.arrival_end_date = null
      // this.filter.departure_begin_date = null
      // this.filter.departure_end_date = null
      this.$refs.vuetable.refresh()
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    formatStatus (value) {
      if (value === null || value === undefined) {
        return ' - '
      }

      let statusList = [
        {
          'id': 0,
          'label': this.$i18n.t('form.filaprocesso.situacao.waiting'),
          'color': '#ababab'
        },
        {
          'id': 1,
          'label': this.$i18n.t('form.filaprocesso.situacao.running'),
          'color': '#f1ba26'
        },
        {
          'id': 2,
          'label': this.$i18n.t('form.filaprocesso.situacao.success'),
          'color': '#5ec65f'
        },
        {
          'id': 3,
          'label': this.$i18n.t('form.filaprocesso.situacao.cancel'),
          'color': '#f1ba26'
        },
        {
          'id': 4,
          'label': this.$i18n.t('form.filaprocesso.situacao.error'),
          'color': '#ff0000'
        },
        {
          'id': 5,
          'label': this.$i18n.t('form.filaprocesso.situacao.stopped'),
          'color': '#ff0000'
        }
      ]

      let status = statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })

      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()

      if (this.$route.query.process) {
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.vuetable.$data.tableData[0] })
          }
        }
      }
    },
    fileDownload (option) {
      this.isLoading = true
      TariffService.getImportedFile(option.rowData.nomeArquivo).then(res => {
        const url = global.window.URL.createObjectURL(new Blob([res.data]))
        const link = global.document.createElement('a')
        link.href = url
        link.setAttribute('download', option.rowData.nomeArquivoOriginal)
        global.document.body.appendChild(link)
        link.click()
        this.isLoading = false
      }).catch(e => {
        this.isLoading = false
      })
    }
  },
  computed: {
    vTableFields () {
      return this.fieldsTable
    },

    process () {
      return this.$route.params.process || null
    },
    filters () {
      return this.$store.getters.aplliedFilters || null
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    }
  },
  watch: {
    process: function () {
      this.filter.internal_code = this.$route.params.process
      this.filters.filProcesso = this.$route.params.process
      this.$refs.internal_code.value = this.$route.params.process

      this.applyFilter()
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style>
  .swal2-popup {
    width: inherit !important;
  }
</style>
