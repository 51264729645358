<template>
  <div class="filter-position">
    <slot name="ContentSideBarButtons">
    </slot>
    <quick-sidebar>
      <template slot="Content">
        <slot name="ContentSideBar">
        </slot>
      </template>
    </quick-sidebar>
  </div>
</template>

<script>
import QuickSidebar from '@/components/QuickSidebar.vue'
export default {
  name: 'FilterSideBar',
  components: {
    QuickSidebar
  },
  methods: {
    showQuickSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.add('has-backdrop')
      sideBar.classList.add('shined')
    }
  }
}
</script>

<style scoped>
  .filter-position {
    margin-top: -50px;
  }
  @media (min-width: 350px) and (max-width: 475px) {
    .filter-position {
      margin-top: 0px !important;
    }
    .filter-position ul {
      min-width: inherit;
    }
  }
</style>
